<template>
  <div id="profile">
    <div class="columns is-multiline">
      <div class="column p-1 is-half-desktop">
        <FormProfile @submit="submit_information" :userData="userData"></FormProfile>
      </div>
    </div>

    <modal-branch :show="new_branch" :branch="branch" @submit="submit_branch" @close="new_branch=false; branch={};"></modal-branch>

  </div>
</template>

<script>
import FormProfile from '@/components/Form/Profile'
import ModalBranch from '@/components/Modal/Branch'

export default {
  name: 'MemberProfile',
  components:{ FormProfile, ModalBranch },
  props: {
    userData: Object,
    loaded: Object,
    branchs: Array,
  },
  data(){
    return {
      saving: false,
      new_branch: false,
      branch: {},
    }
  },
  // mounted(){
  //   if(!this.loaded.get_branch){
  //     this.$emit('get_branch');
  //   }
  // },
  methods:{
    submit_information(input){
      if(this.saving)return false;

      this.saving = true;
      var url = this.api+'user/save_information/'+this.secret;
      var data = new FormData();
      data.set("name", input.name);
      data.set("lname", input.lname);
      data.set("id", this.userData.id);
      data.set("token", this.userData.token);
      data.set("secret", this.secret);
      this.Axios.post(url, data)
      .then((response)=>{
        console.log(response.data);
        if(response.data.error==0){
          Object.keys(input).forEach((key) => {
            this.userData[key] = input[key]
          });
          this.$swal("","บันทึกข้อมูลสำเร็จ!","success");
        }else{
          this.logout_of_system();
        }
      })
      .catch((error)=>{
        console.error(error);
        this.$swal("","","warning");
      })
      .then(()=>{
        this.saving = false;
      })
    },
    submit_branch(input){
      if(!input.id){
        this.submit_new_branch(input)
      }else{
        this.submit_edit_branch(input)
      }
      this.new_branch = false;
    },
  }
}
</script>
